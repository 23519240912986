import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { AccountsService } from '../../accounts.service';

@Component({
    selector: 'app-login-normal',
    templateUrl: './login-normal.component.html',
    styleUrls: ['./login-normal.component.scss']
})
export class LoginNormalComponent implements OnInit {
    isTranslationsUploaded$ = this.accountsService.isTranslationsUploaded$;
    supportEmail = 'https://suporte.inicie.digital/';
    backgroundLogin = '';
    imageSideLogin = '';
    logoLogin = '';

    allowedProviders: { password: boolean, google: boolean, microsoft: boolean, sso: boolean } = {
        password: false, google: false, microsoft: false, sso: false
    };

    providers: { external_id: string }[] = [];

    // Traductions
    i18n: any = {};

    constructor(
        private accountsService: AccountsService,
        private sharedService: SharedService,
        public platModalService: PlatformModalsService,
        private localStorageService: LocalStorageService
    ) {}

    ngOnInit(): void {
        this.getTranslations();
        this.getWhoamiData();
    }

    getTranslations(): void {
        this.i18n = { ...this.sharedService.getTranslationsOf('Login') };
    }

    getWhoamiData(): void {
        if (this.localStorageService.getLocalStorageItem('main_logo')) {
            this.logoLogin = JSON.parse(localStorage.getItem('main_logo') || '');
        }

        if (this.localStorageService.getLocalStorageItem('login_background')) {
            this.backgroundLogin = JSON.parse(localStorage.getItem('login_background') || '');
        }

        if (this.localStorageService.getLocalStorageItem('login_image')) {
            this.imageSideLogin = JSON.parse(localStorage.getItem('login_image') || '');
        }

        if (this.localStorageService.getLocalStorageItem('support_email')) {
            this.supportEmail = JSON.parse(localStorage.getItem('support_email') || '');
        }

        if (this.localStorageService.getLocalStorageItem('allowedProviders')) {
            this.providers = JSON.parse(localStorage.getItem('allowedProviders') || '');

            if (this.providers.length) {
                this.providers.forEach((element) => {
                    this.allowedProviders[element.external_id] = true;
                });
            }
        }
    }
}
