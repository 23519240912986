import {
    animate, AUTO_STYLE, state, style, transition, trigger
} from '@angular/animations';
import {
    Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild,
    ViewChildren
} from '@angular/core';
import {
    FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup
} from '@angular/forms';
import {
    debounceTime, distinctUntilChanged, map,
    Subscription
} from 'rxjs';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

import { DisciplinesModelsService } from '../../../shared/disciplines-models.service';
import { PaginationComponent } from '../../pagination/pagination.component';

const DEFAULT_DURATION = 250;

@Component({
    selector: 'app-export-contents-modal',
    templateUrl: './export-contents-modal.component.html',
    styleUrls: ['./export-contents-modal.component.scss'],
    animations: [
        trigger('collapse', [
            state('true => false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1, })),
            state('false => true', style({ height: '0px', visibility: 'hidden', opacity: 0, })),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`))
        ])
    ]
})
export class ExportContentsModalComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(PaginationComponent) pagination: PaginationComponent | any;
    @ViewChildren('app-select-discipline-box') selectDisciplineBoxComponents!: ElementRef;

    typedData = new FormControl();

    paginationParams: any = {};
    activePage = 1;
    per_page = 4;
    // page: number = 1;

    query = '';

    title: string;
    loading: boolean;
    subTitle: string;
    selectAll: string;
    available: string;
    buttonSave: string;
    modelForm: UntypedFormGroup;
    contentsFormEvent: any = [];
    collapseSection = true;
    filteredSections: any = [];
    textFound: any = [];
    alteredFilter = false;
    controlAllSelect = false;
    templateSelectionMode = true;
    templateSectionMode = false;
    templateCode: any = [];
    targetDisciplinesToInput: any[] = [];
    targetSectionsToInput: any[] = [];

    dataDisciplines: any = [];
    filtered: any = [];
    targetDisciplineName = '';
    templateDisciplineMode = false;
    templateSectionImportMode = false;
    subscriptions: Subscription[] = [];

    @Input() type: Type = { type: 'template' };

    @Input() isItInTheTemplates: boolean;
    @Input() contentSelectionMode = true;
    @Input() disciplinesRawData : any = [];
    @Input() disciplineCode: any = [];
    @Input() sections: any = [];
    @Input() i18n: any = [];
    @Input() receiveExternalIDContent = '';
    @Input() getNextDiscipline;
    @Input() templateMode;
    @Input() isDuplicate;

    @Output() sendingImportTemplate: EventEmitter<boolean> = new EventEmitter();
    @Output() sendDisciplineData: EventEmitter<boolean> = new EventEmitter();
    @Output() getSectionsTemplate: EventEmitter<boolean> = new EventEmitter();
    @Output() getSectionsByDiscipline: EventEmitter<string> = new EventEmitter();
    @Output() getDisciplines: EventEmitter<any> = new EventEmitter();
    @Output() importTemplateInDiscipline: EventEmitter<boolean> = new EventEmitter();
    @Output() sendDateResource: EventEmitter<boolean> = new EventEmitter();
    @Output() sendDatesResourcesValues = new EventEmitter();
    @Output() isDuplicateChange = new EventEmitter();

    isImportModelOpen$ = this.disciplinesModelsService.isImportModelOpen$;
    isLoadingModal$ = this.disciplinesModelsService.isLoadingModal$;

    constructor(
        private disciplinesModelsService: DisciplinesModelsService,
        private formBuilder: UntypedFormBuilder,
        public platformModalService: PlatformModalsService,
    ) { }

    ngOnInit(): void {
        this.createContentsForm();
        this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
        this.getLoading();
        this.receiveTypedData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filteredSections = changes?.sections?.currentValue;
        const rawData = changes?.disciplinesRawData?.currentValue;
        this.receiveDisciplineRawData(rawData);
    }

    selectAllSections() {
        this.controlAllSelect = !this.controlAllSelect;
    }

    closeModalImport() {
        this.disciplinesModelsService.closeExportContentsModal();
    }

    toggleSection() {
        this.collapseSection = !this.collapseSection;
    }

    getLoading() {
        this.isLoadingModal$
            .subscribe(
                (loading) => { this.loading = loading; }
            );
    }

    getResourcesDates(): Promise<any> {
        console.log('this.modelForm.value ', this.modelForm.value);
        const items = {
            sections: [],
            contents: [],
        };

        if (this.modelForm.value.sourceSections && this.modelForm.value.sourceSections.length) {
            items.sections = this.modelForm.value.sourceSections;
        }

        if (this.modelForm.value.sourceContents && this.modelForm.value.sourceContents.length) {
            items.contents = this.modelForm.value.sourceContents;
        }

        console.log('items ', items);
        return new Promise((resolve, reject) => {
            this.disciplinesModelsService.getDatesByResources(this.disciplineCode, items).subscribe({
                next: (resourcesDates: any) => {
                    resolve(resourcesDates);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    preRegisteredDates;
    async verifyPreRegisteredDates(): Promise<boolean> {
        const preRegisteredDates = await this.getResourcesDates();
        let hasSomeResourceDateTrue = false;
        if (preRegisteredDates) {
            if (preRegisteredDates.notification_only_videoconference_expired) {
                this.closeModalImport();
                this.platformModalService.toggle('message', `${this.i18n.disciplines_pre_registered_videoconference_expired} ${this.i18n.disciplines_pre_registered_update_dates_try_again}`, 'close');
                return false;
            }
            hasSomeResourceDateTrue = Object.entries(preRegisteredDates)
                .filter(([key, value]) => key.startsWith('date_'))
                .some(([key, value]) => value === true);
        }

        if (hasSomeResourceDateTrue) {
            console.log('hasSomeResourceDateTrue ', hasSomeResourceDateTrue);
            // this.disciplinesModelsService.closeExportContentsModal();
            this.preRegisteredDates = preRegisteredDates;
            // this.disciplinesModelsService.isPreRegisteredDatesModalOpen$.next(true);
        }

        return hasSomeResourceDateTrue;
    }

    getPreviousSelectedSectionAndContents() {
        const sub = this.disciplinesModelsService.sectionsAndContentsSelected$.subscribe({
            next: (sectionsAndContentsSelected) => {
                console.log('sectionsAndContentsSelected ', sectionsAndContentsSelected);
                this.modelForm.controls.sourceContents.setValue(sectionsAndContentsSelected.sourceContents);
                this.modelForm.controls.sourceSections.setValue(sectionsAndContentsSelected.sourceSections);
            }
        });

        this.subscriptions.push(sub);
    }

    getPreRegisteredDatesCheckBoxes() {
        const sub = this.disciplinesModelsService.preRegisteredDates$.subscribe({
            next: (preRegisteredDates) => {
                console.log('preRegisteredDates ', preRegisteredDates);
                this.preRegisteredDates = preRegisteredDates;
                this.isPreRegisteredDateTemplateMode();
            }
        });

        this.subscriptions.push(sub);
    }

    datesResourcesValues;
    templateType = 'contentSelectionMode';
    getDatesResourcesValues(datesResourcesValues) {
        this.sendDatesResourcesValues.emit(datesResourcesValues);

        this.gettingDisciplines(this.activePage, this.per_page);
        this.isTemplateDisciplineMode();

        if (this.isDuplicate) {
            this.isDuplicate = false;
            this.disciplinesModelsService.closeExportContentsModal();
        }
    }

    isTemplateDisciplineMode() {
        // TODO change template view mode in HTML of this component by templateType variable
        // to more info see how to do in continueImport method in If(this.hasAnyPreRegisteredDate)
        this.templateType = 'templateDisciplineMode';
        this.contentSelectionMode = false;
        this.templateDisciplineMode = true;
        this.templateSelectionMode = false;
        this.templateSectionImportMode = false;

        this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
    }

    isPreRegisteredDateTemplateMode() {
        this.templateType = 'preRegisteredSelectionMode';
        this.contentSelectionMode = false;
        this.templateDisciplineMode = false;
        this.templateSelectionMode = false;
        this.templateSectionImportMode = false;

        this.title = this.i18n.disciplines_pre_registered_export_title;
        this.subTitle = '';
    }

    hasAnyPreRegisteredDate = false;
    async continueImport(checkPreRegisteredDates: boolean, isAfterSelectPreRegisteredDates?: boolean) {
        if (checkPreRegisteredDates) {
            this.loading = true;
            this.sendEnventImportTemplate();
            this.hasAnyPreRegisteredDate = await this.verifyPreRegisteredDates();
            this.loading = false;

            console.log('this.hasAnyPreRegisteredDate ', this.hasAnyPreRegisteredDate);

            if (this.hasAnyPreRegisteredDate) {
                this.isPreRegisteredDateTemplateMode();
                // this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
                return;
            }
        }

        console.log('isAfterSelectPreRegisteredDates ', isAfterSelectPreRegisteredDates);

        if (isAfterSelectPreRegisteredDates) {
            this.getPreviousSelectedSectionAndContents();
        }

        console.log('this.isItInTheTemplates ', this.isItInTheTemplates);
        console.log('this.contentSelectionMode ', this.contentSelectionMode);
        console.log('this.templateSelectionMode ', this.templateSelectionMode);
        console.log('this.templateDisciplineMode ', this.templateDisciplineMode);

        if (this.isItInTheTemplates) {
            this.contentSelectionMode = !this.contentSelectionMode;
            this.templateSelectionMode = !this.templateSelectionMode;
            this.templateDisciplineMode = !this.templateDisciplineMode;
            this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
        } else {
            this.templateDisciplineMode = true;
            this.templateSectionImportMode = false;
            this.templateSelectionMode = false;
            this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
            this.gettingSectionsTemplate();
            console.log('filteredSections ', this.filteredSections);
        }
    }

    continueTargetDisciplines() {
        this.templateSelectionMode = false;
        this.templateDisciplineMode = false;
        this.templateSectionImportMode = true;
        this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
        this.gettingSectionsTemplate();
    }

    buttonName = '';
    continueTargetSections() {
        this.contentSelectionMode = false;
        this.templateSectionMode = true;
        this.templateSectionImportMode = true;
        this.templateSelectionMode = false;
        this.templateDisciplineMode = false;
        this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
    }

    continueTargetSectionsImport() {
        this.contentSelectionMode = false;
        this.templateSectionMode = false;
        this.templateSectionImportMode = true;
        this.templateSelectionMode = false;
        this.templateDisciplineMode = false;
        this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
        this.gettingSectionsTemplate();
    }

    countTemplate = 0;
    continueSelectedDisciplines() {
        this.templateSectionMode = true;
        console.log('this.modelForm.value ', this.modelForm.value);

        this.buttonName = this.i18n.disciplines_continue;

        if (this.countTemplate === this.targetDisciplinesToInput.length - 1) {
            this.buttonName = this.i18n.disciplines_save;
        }

        if (this.countTemplate <= this.targetDisciplinesToInput.length - 1) {
            this.nextDiscipline();
            return;
        }
        this.emitDisciplineData();
    }

    nextDiscipline() {
        console.log('this.targetDisciplinesToInput ', this.targetDisciplinesToInput);

        const disciplineExternalId = this.targetDisciplinesToInput[this.countTemplate].external_id;
        this.targetDisciplineName = this.targetDisciplinesToInput[this.countTemplate].title;
        this.continueTargetSections();
        this.getSectionByDiscipline(disciplineExternalId);
        this.countTemplate += 1;
        this.targetSectionsToInput = [];
    }

    getSectionByDiscipline(disciplineExternalId: string) {
        this.getSectionsByDiscipline?.emit(disciplineExternalId);
    }

    gettingSectionsTemplate() {
        this.getSectionsTemplate?.emit();
    }

    gettingDisciplines(page, per_page) {
        const params: any = {
            page,
            per_page,
            query: this.query
        };
        this.getDisciplines?.emit(params);
    }

    receiveDisciplineRawData(disciplineData) {
        this.dataDisciplines = disciplineData?.items;
        this.activePage = disciplineData?.page;
        if (this.dataDisciplines?.length) {
            this.refreshPagination(disciplineData);
        }
    }

    search(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.value) {
            const { value } = target;
            this.alteredFilter = true;

            this.sections.forEach((section, index) => {
                this.filteredSections = section.contents.filter((content, i) => {
                    if (content.title.toLowerCase().includes(value.toLocaleLowerCase())) {
                        this.textFound = i;
                        return [this.sections[index]];
                    }
                    return [];
                });
            });
        } else {
            this.filteredSections = this.sections;
            this.alteredFilter = false;
        }
    }

    modalMode(contentSelectionMode, templateSelectionMode) {
        console.log('contentSelectionMode ', contentSelectionMode);
        // TODO use templateType variable to change translations and change IF to SWITCH
        if (this.isItInTheTemplates) {
            if (contentSelectionMode === true) {
                console.log('AQUIII');

                this.title = this.i18n.disciplines_export_content;
                this.subTitle = this.i18n.disciplines_select_to_export;
                this.available = this.i18n.disciplines_contents_available;
                this.selectAll = this.i18n.disciplines_select_all;
                this.buttonSave = this.i18n.disciplines_continue;
            } else {
                this.title = this.i18n.disciplines_export_tamplate;
                this.subTitle = this.i18n.disciplines_select_classes_to_export;
                this.available = this.i18n.disciplines_classes_available;
                this.selectAll = this.i18n.disciplines_select_all;
                this.buttonSave = this.i18n.disciplines_save;
            }
            if (this.templateSectionMode) {
                this.title = this.i18n.disciplines_import_modal_select_section;
                this.subTitle = this.i18n.disciplines_import_modal_select_section_content;
                this.buttonSave = this.i18n.disciplines_export;
            }
        } else {
            if (templateSelectionMode === false) {
                this.title = this.i18n.disciplines_import;
                this.subTitle = this.i18n.disciplines_select_to_import;
                this.available = this.i18n.disciplines_contents_available;
                this.selectAll = this.i18n.disciplines_select_all;
                this.buttonSave = this.i18n.disciplines_import;
            } else {
                this.title = this.i18n.disciplines_select_classes;
                this.subTitle = this.i18n.disciplines_select_classes_to_import;
                this.available = this.i18n.disciplines_classes_available;
                this.selectAll = this.i18n.disciplines_select_all;
                this.buttonSave = this.i18n.disciplines_continue;
            }
            if (this.templateSectionImportMode) {
                this.title = this.i18n.disciplines_import_modal_select_section;
                this.subTitle = this.i18n.disciplines_import_modal_select_section_content;
                this.buttonSave = this.i18n.disciplines_import;
            }
        }
    }

    // Call replicate post
    async emitDisciplineData() {
        // this.sendingDisciplineCode.emit(this.disciplineCode); //enviando o evento com o código da turma
        this.sendDisciplineData.emit(this.modelForm.value); // envia dados para o post da importação

        this.closeModalImport();
    }

    sendEnventImportTemplate() {
        console.log('this.modelForm.value sendEnventImportTemplate', this.modelForm.value);

        this.sendingImportTemplate.emit(this.modelForm.value);
    }

    createContentsForm() {
        if (this.isItInTheTemplates) {
            this.modelForm = this.formBuilder.group({
                sourceContents: [],
                sourceSections: [],
                targetDisciplines: this.formBuilder.array([]),
                targetSections: this.formBuilder.array([]),
                keepDisciplineInSync: [false],
            });
            // this.modelForm?.valueChanges
            //     .subscribe(
            //         () => {
            //             this.sendingImportTemplate.emit(this.modelForm.value);
            //         }
            //     );
        } else {
            this.modelForm = this.formBuilder.group({
                sourceContents: [],
                sourceSections: [],
                targetDisciplines: this.formBuilder.array([]),
                targetSections: this.formBuilder.array([]),
                keepDisciplineInSync: [false],
            });
            // this.modelForm?.valueChanges
            //     .subscribe(
            //         () => {
            //             this.sendingImportTemplate.emit(this.modelForm.value);
            //         }
            //     );
        }
    }

    receivesCheckedContents(dataEvent) {
        this.contentsFormEvent = dataEvent;
        this.modelForm.controls.sourceContents.setValue(this.contentsFormEvent);
    }

    receivesCheckedSection(dataEvent) {
        if (this.templateSectionMode) {
            this.modelForm.controls.targetSections.setValue(dataEvent);
        } else {
            this.modelForm.controls.sourceSections.setValue(dataEvent);
        }
    }

    checkSectionChange(section: any) {
        console.log('event checkSectionChange', section);

        const targetSections: UntypedFormArray = this.modelForm?.get('targetSections') as UntypedFormArray;
        if (section?.checked) {
            targetSections.push(new UntypedFormControl(section.external_id));
            const hasSectionChecked = this.targetSectionsToInput.some((sd) => sd.external_id === section.external_id);
            if (!hasSectionChecked) {
                this.targetSectionsToInput.push(section);
            }
        } else {
            let i = 0;
            targetSections.controls.forEach((item: any) => {
                if (item.value === section?.external_id) {
                    this.targetSectionsToInput.splice(this.targetSectionsToInput.indexOf(section?.external_id), 1);
                    targetSections.removeAt(i);
                    return;
                }
                i += 1;
            });
        }

        this.sendEnventImportTemplate();
    }

    isSelectedAllDisciplines = false;
    selectAllDisciplines() {
        console.log('this.dataDisciplines ', this.dataDisciplines);
        console.log('selectDisciplineBoxComponents ', this.selectDisciplineBoxComponents);
        this.isSelectedAllDisciplines = !this.isSelectedAllDisciplines;

        this.dataDisciplines = this.dataDisciplines.map((element) => {
            let disciplineObj = { ...element, checked: true };
            if (!this.isSelectedAllDisciplines) {
                disciplineObj = { ...element, checked: false };
            }
            return disciplineObj;
        });

        this.dataDisciplines.forEach((element) => {
            this.checkDisciplineChange(element);
        });
        console.log('this.targetDisciplinesToInput ', this.targetDisciplinesToInput);
    }

    checkDisciplineChange(discipline: any) {
        const targetDisciplines: UntypedFormArray = this.modelForm?.get('targetDisciplines') as UntypedFormArray;
        if (discipline?.checked) {
            targetDisciplines.push(new UntypedFormControl(discipline.external_id));
            const hasDisciplineChecked = this.targetDisciplinesToInput.some((sd) => sd.external_id === discipline.external_id);
            if (!hasDisciplineChecked) {
                this.targetDisciplinesToInput.push(discipline);
            }
        } else {
            let i = 0;
            targetDisciplines.controls.forEach((item: any) => {
                console.log('item ', item);

                if (item.value === discipline.external_id) {
                    console.log('this.targetDisciplinesToInput.indexOf(discipline) ', this.targetDisciplinesToInput.indexOf(discipline, 0));

                    this.targetDisciplinesToInput.splice(this.targetDisciplinesToInput.indexOf(discipline, 0), 1);
                    targetDisciplines.removeAt(i);
                    return;
                }
                i += 1;
            });
        }

        console.log('this.targetDisciplinesToInput ', this.targetDisciplinesToInput);
        console.log('discipline checkDisciplineChange', discipline);

        this.sendEnventImportTemplate();
    }

    checkTemplateChange(event: any) {
        console.log('event checkTemplateChange', event);

        this.targetDisciplineName = event.title;
        const targetDisciplines: UntypedFormArray = this.modelForm?.get('targetDisciplines') as UntypedFormArray;
        if (event?.event?.target?.checked) {
            targetDisciplines.push(new UntypedFormControl(event?.event?.target?.value));
            this.sendEnventImportTemplate();
            this.continueImport(false);
            // this.gettingSectionsTemplate();
        } else {
            let i = 0;
            targetDisciplines.controls.forEach((item: any) => {
                if (item.value === event?.event?.target?.value) {
                    targetDisciplines.removeAt(i);
                    return;
                }
                i += 1;
            });
        }
    }

    goToPage({ activePage }): void {
        const page = activePage;

        this.gettingDisciplines(page, this.per_page);

    // if(!this.loading) {
    //   this.dataDisciplines = this.disciplinesRawData.items;
    //   this.activePage = this.disciplinesRawData.page;
    // }
    }

    refreshPagination(response): void {
        this.paginationParams = {
            items: [...Array(response.total_items).keys()],
            itemsPerPage: this.per_page,
            visibleButtons: 5,
            activePage: this.activePage,
            query: this.query
        };

        if (this.pagination) {
            this.pagination.params = this.paginationParams;
            this.pagination.initPaginationLib(true);
        }
    }

    receiveTypedData() {
        this.typedData.valueChanges
            .pipe(
                map((value) => value.trim()),
                // tap(value => this.searchVerification(value)),
                // filter(value => value.length > 1),
                // filter(value => value != null),
                distinctUntilChanged(),
                debounceTime(200),
                map((value: any) => this.getSearch(value)),
            ).subscribe();
    }

    getSearch(typedData) {
        let typedDataString = typedData;
        typedDataString = typedData === undefined ? '' : typedData;
        this.query = typedDataString;
        this.activePage = 1;

        this.gettingDisciplines(this.activePage, this.per_page);
    }

    searchVerification(typedData) {
        /*  if(typedData.length > 1) {
      this.query = typedData
      this.gettingDisciplines(this.activePage, this.per_page);
    } else if(typedData.length < 1) {
      this.query = ''
      this.gettingDisciplinesSearch(this.activePage, this.per_page);
    } */
    }

    gettingDisciplinesSearch(page, per_page) {
        const params: any = {
            page,
            per_page,
            query: this.query
        };
        this.getDisciplines?.emit(params);
    }

    ngOnDestroy() {
        if (this.subscriptions.length) {
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
        }
    }
}

export interface Type {
    type: 'private' | 'template';
}
