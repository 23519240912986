import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { TitlePageService } from 'src/app/services/titlePageService/title-page.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
    supportEmail = 'https://suporte.inicie.digital/';
    backgroundLogin = '';
    imageSideLogin = '';
    logoLogin = '';
    userHasToken = false;

    activeRole = '';
    // Traductions
    i18n: any = {};

    constructor(private sharedService: SharedService, private router: Router, private localStorageService: LocalStorageService, private titleService: TitlePageService) {}

    ngOnInit(): void {
        this.getTranslations();
        this.firstLoad();
        this.getWhoamiData();
    }

    getTranslations(): void {
        this.i18n = this.sharedService.getTranslationsOf('Login');
    }

    firstLoad(): void {
        // Se o user tiver token ativo não faz o firstLoad();
        if (this.sharedService.fnUserHasToken() && this.sharedService.fnUserHasValidToken()) {
            this.userHasToken = true;
            this.sharedService.loggedIn.next(true);
            this.getUser();
            // Verifica se veio de algum redirecionamento redirectUrl
            const route = localStorage.getItem('returnUrl') || null;

            if (route !== null && route !== '' && route !== undefined) {
                this.router.navigateByUrl(route);
                this.sharedService.deleteKeyLocalStorage('returnUrl');
                return;
            }

            // Confere se há opção de redirecionamento para Disciplina
            const redirectToDiscipline = localStorage.getItem('redirect_option') === 'true';

            if (this.activeRole === 'guardian') {
                // Guardian dashboard
                this.router.navigate(['communication-app/dashboard-communication-guardian']);
            } else if (redirectToDiscipline) {
                this.router.navigate(['disciplines']);
            } else {
                this.router.navigate(['dashboard']);
            }
        }
    }

    getWhoamiData(): void {
        // set undefined title to get default title from settings
        this.titleService.setTitleSite(undefined);

        if (this.localStorageService.getLocalStorageItem('main_logo')) {
            this.logoLogin = JSON.parse(localStorage.getItem('main_logo') || '');
        }

        if (this.localStorageService.getLocalStorageItem('login_background')) {
            this.backgroundLogin = JSON.parse(localStorage.getItem('login_background') || '');
        }

        if (this.localStorageService.getLocalStorageItem('login_image')) {
            this.imageSideLogin = JSON.parse(localStorage.getItem('login_image') || '');
        }

        if (this.localStorageService.getLocalStorageItem('support_email')) {
            this.supportEmail = JSON.parse(localStorage.getItem('support_email') || '');
        }
    }

    getUser(): void {
        this.sharedService.getUser().subscribe({
            next: (response: any) => {
                if (response) {
                    this.activeRole = response.active_role;
                }
            }
        });
    }
}
