
<div id="under-aside">
  <div class="invisible-full-page-div" (click)="deactivateAside()"></div>
  <aside id="aside" #sidebar>
    <header class="header">
      <button class="aside__expand-btn" (click)="toggleAside('emit')" tabindex="1">
        <a class="menu-expand-icon">
          <span class="menu-expand-icon__line line-1"></span>
          <span class="menu-expand-icon__line line-2"></span>
          <span class="menu-expand-icon__line line-3"></span>
        </a>
      </button>
    </header>

    <div class="aside__pages-wrapper" (window:resize)="onResize($event)">
        <section class="aside__pages lg:m-auto">
            <!-- Validação adicionada pois não poderia tirar a permissão dashboard (obrigatória) -->
            <ng-container *ngIf="user?.role_type !== 'guardian'">
                <button class="aside__btn dashboard" tabindex="1" *appCheckPermissions="'dashboard';type:'list'" [routerLink]="['/dashboard']">
                  <div class="aside__btn-content">
                    <span class="iconify-inline aside__icon" data-icon="ant-design:home-outlined"></span>
                    <p class="aside__link-name">{{ i18n.home }}</p>
                  </div>
                  <div class="aside__tooltip">{{ i18n.home }}</div>
                </button>
            </ng-container>

          <button class="aside__btn orgunits" tabindex="1" *appCheckPermissions="'ou';type:'list'" [routerLink]="['/orgunits']">
            <div class="aside__btn-content">
              <span class="iconify-inline aside__icon" data-icon="ri:community-line"></span>
              <p class="aside__link-name">{{ i18n.structure }}</p>
            </div>
            <div class="aside__tooltip">{{ i18n.structure }}</div>
          </button>

          <button class="aside__btn communication-app" tabindex="1" *appCheckPermissions="'channel'; type:'list'" [routerLink]="['/communication-app/dashboard-communication-admin']">
            <div class="aside__btn-content">
                <span class="iconify-inline aside__icon" data-icon="iconoir:chat-bubble"></span>
              <p class="aside__link-name">{{ i18n.communication_app_communications }}</p>
            </div>
            <div class="aside__tooltip">{{ i18n.communication_app_communications }}</div>
          </button>

          <button class="aside__btn disciplines" tabindex="1" *appCheckPermissions="'disciplines';type:'list'" [routerLink]="['/disciplines']" >
            <div class="aside__btn-content">
              <span class="iconify-inline aside__icon" data-icon="material-symbols:school-outline-rounded"></span>
              <p class="aside__link-name">{{ i18n.classes }}</p>
            </div>
            <div class="aside__tooltip">{{ i18n.classes }}</div>
          </button>

          <button class="aside__btn sports" tabindex="1" *appCheckPermissions="'sport';type:'list'" [routerLink]="['/sports']" >
            <div class="aside__btn-content">
              <span class="iconify-inline aside__icon" data-icon="fluent-mdl2:more-sports"></span>
              <p class="aside__link-name">{{ i18n.sports }}</p>
            </div>
            <div class="aside__tooltip">{{ i18n.sports }}</div>
          </button>

          <button class="aside__btn content-planner" tabindex="1" *appCheckPermissions="'plannings';type:'list'" [routerLink]="['/class-planner']" >
            <div class="aside__btn-content">
              <span class="iconify aside__icon" data-icon="fluent:book-add-24-regular"></span>
              <p class="aside__link-name">{{ i18n.planner_content_planning }}</p>
            </div>
            <div class="aside__tooltip">{{ i18n.planner_content_planning }}</div>
          </button>

          <button class="aside__btn certifications" tabindex="1" *appCheckPermissions="'certifications';type:'list'" [routerLink]="['/certifications']">
            <div class="aside__btn-content">
              <span class="iconify-inline aside__icon" data-icon="ph:certificate"></span>
              <p class="aside__link-name">{{ i18n.certifications }}</p>
            </div>
            <div class="aside__tooltip">{{ i18n.certifications }}</div>
          </button>

          <button class="aside__btn reports" tabindex="1" *appCheckPermissions="'report_center';type:'list'" [routerLink]="['/reports']">
            <div class="aside__btn-content">
              <span class="iconify-inline aside__icon" data-icon="mdi:report-box-outline"></span>
              <p class="aside__link-name">{{i18n.reports_report_central}}</p>
            </div>
            <div class="aside__tooltip">{{i18n.reports_report_central}}</div>
          </button>

          <button class="aside__btn permissions" tabindex="1" *appCheckPermissions="'permissioning';type:'list'" [routerLink]="['/permissions']">
            <div class="aside__btn-content">
              <span class="iconify-inline aside__icon" data-icon="ic:sharp-security"></span>
              <p class="aside__link-name">{{ i18n.profile_permissions }}</p>
            </div>
            <div class="aside__tooltip">{{ i18n.profile_permissions }}</div>
          </button>

          <button class="aside__btn assiduity" tabindex="1" *appCheckPermissions="'assiduity';type:'list'" [routerLink]="['/assiduity']">
            <div class="aside__btn-content">
              <span class="iconify-inline aside__icon" data-icon="tabler:file-time"></span>
              <p class="aside__link-name">{{ i18n.assiduity}}</p>
            </div>
            <div class="aside__tooltip">{{ i18n.assiduity }}</div>
          </button>

          <button class="aside__btn disciplines-models" tabindex="1" *appCheckPermissions="'disciplines_replicate';type:'list'" [routerLink]="['/disciplines-models']">
            <div class="aside__btn-content">
              <span class="iconify-inline aside__icon" data-icon="mdi:file-import-outline"></span>
              <p class="aside__link-name">{{ i18n.class_models }}</p>
            </div>
            <div class="aside__tooltip">{{ i18n.class_models }}</div>
          </button>

          <ng-container *ngIf="isGoogle">
            <button class="aside__btn import_spreadsheet" tabindex="1" *appCheckPermissions="'spreadsheet';type:'create'" [routerLink]="['/import-spreadsheet']">
                <div class="aside__btn-content">
                  <span class="iconify-inline aside__icon icon-transfer" data-icon="bx:transfer" rotate="90deg"></span>
                  <p class="aside__link-name">{{ i18n.import_spreadsheet }}</p>
                </div>
                <div class="aside__tooltip">{{ i18n.import_spreadsheet }}</div>
            </button>
          </ng-container>


          <button class="aside__btn users" tabindex="1" *appCheckPermissions="'users';type:'list'" [routerLink]="['/users']">
            <div class="aside__btn-content">
              <span class="iconify-inline aside__icon" data-icon="majesticons:users-line"></span>
              <p class="aside__link-name">{{ i18n.users }}</p>
            </div>
            <div class="aside__tooltip">{{ i18n.users }}</div>
          </button>

          <button class="aside__btn chromebook-dashboard-extension" tabindex="1" *ngIf="canShowChromeExtensionDashboard"  [routerLink]="['/chromebook-dashboard-extension']">
            <div class="aside__btn-content">
              <span class="iconify-inline aside__icon" data-icon="mdi:laptop-account"></span>
              <p class="aside__link-name">{{ i18n?.title_chromebook_extension}}</p>
            </div>
            <div class="aside__tooltip">{{ i18n?.title_chromebook_extension}}</div>
          </button>

          <!-- <button class="aside__btn" tabindex="1" [routerLink]="['/classes']">
            <div class="aside__btn-content">
              <a class="aside__icon file-import-icon"></a>
              <p class="aside__link-name">Modelos de turma</p>
            </div>
            <div class="aside__tooltip">Modelos de turma</div>
          </button> -->

          <!-- <button class="aside__btn" tabindex="1" [routerLink]="['/import']">
            <div class="aside__btn-content">
              <a class="aside__icon arrows-up-down-icon"></a>
              <p class="aside__link-name">Importação de estrutura</p>
            </div>
            <div class="aside__tooltip">Importação de estrutura</div>
          </button> -->

          <button class="aside__btn settings" tabindex="1" *appCheckPermissions="'settings';type:'list'" [routerLink]="['/settings']">
            <div class="aside__btn-content">
              <span class="iconify-inline aside__icon" data-icon="ri:settings-5-fill"></span>
              <p class="aside__link-name">{{ i18n.settings }}</p>
            </div>
            <div class="aside__tooltip">{{ i18n.settings }}</div>
          </button>
        </section>

    </div>

    <footer class="footer">
      <button class="aside__btn" tabindex="1" (click)="openModalUseTerms()">
        <div class="aside__btn-content text-left" >
          <span class="iconify-inline aside__icon" data-icon="uil:file-check-alt"></span>
          <!-- <span class="iconify-inline aside__icon" data-icon="ri:settings-5-fill"></span> -->
          <p class="aside__link-name"><span> {{i18n.use_terms_title}} {{i18n.use_terms_and}} <br>{{i18n.policy_privacy_title}}</span></p>
        </div>
        <div class="aside__tooltip">{{i18n.use_terms_title}} {{i18n.use_terms_and}} {{i18n.policy_privacy_title}}</div>
      </button>

      <button class="language__btn aside__languages-btn overflow-hidden" tabindex="1" (click)="toggleLanguage($event)">
        <div class="aside__btn-content language__content">
          <span class="iconify-inline aside__icon" data-icon="mdi:web"></span>
          <p class="aside__link-name">{{ selectedLanguage }}</p>
          <span class="iconify-inline language__arrow aside__icon" data-icon="bxs:right-arrow" style="width: 15px; height: 15px;"></span>
          <!-- <i class="arrow-top-icon language__arrow aside__icon" style="width: 15px; height: 15px;"></i> -->
        </div>

        <div class="language__dropdown">
          <div class="language__item" *ngFor="let lang of otherLanguages" (click)="switchLanguage($event, lang.locale)">{{ lang.name }}</div>
        </div>
        <div class="aside__tooltip">{{ i18n.language }}</div>
      </button>
    </footer>
  </aside>
</div>

<app-modal-use-terms></app-modal-use-terms>
<app-modal-policy-and-privacy></app-modal-policy-and-privacy>


